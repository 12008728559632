import { useTheme } from '@emotion/react';
import {
  AddCircle,
  HomeOutlined,
  OndemandVideoOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { BottomNavigationAction, Box, useMediaQuery } from '@mui/material';
import React from 'react';

function MobileView({
  setTabValue,
  setShowLeft,
  setSelectedChat,
  tabValue,
  handleHeaderTabClick,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    isMobile && (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column', // Align navigation items vertically
          alignItems: 'center',
          justifyContent: 'space-between',
          height: isMobile ? '80px' : '100%',
          padding: '16px 0',
          backgroundColor: theme.palette.background.paper,
          bottom: '64px',
          width: '100%',
        }}
      >
        <BottomNavigationAction
          label="Home"
          showLabel={true}
          icon={<HomeOutlined />}
          onClick={() => {
            setTabValue(0);
            if (isMobile) {
              setShowLeft(false);
            }
            setSelectedChat(null);
          }}
          selected={tabValue === 0}
        />
        <BottomNavigationAction
          label="Post"
          showLabel={true}
          icon={<AddCircle sx={{ fontSize: 40 }} color="primary" />}
          onClick={() => handleHeaderTabClick('CREATEPOST')}
          selected={tabValue === 2}
        />
        <BottomNavigationAction
          label="Discover"
          showLabel={true}
          icon={<SearchOutlined />}
          onClick={() => {
            setTabValue(1);
            if (isMobile) {
              setShowLeft(false);
            }
          }}
          selected={tabValue === 1}
        />

        <BottomNavigationAction
          label="Soapbox"
          showLabel={true}
          icon={<OndemandVideoOutlined />}
          onClick={() => {
            setTabValue(3);
            if (isMobile) {
              setShowLeft(false);
            }
          }}
          selected={tabValue === 3}
        />
      </Box>
    )
  );
}

export default MobileView;
