import React, { useState } from 'react';
import {
  Typography,
  Avatar,
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  useMediaQuery,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { logOut, setEnableRequest } from '../../redux/reducers/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../components/hooks';
import {
  Close,
  Email,
  NotificationAddOutlined,
  Notifications,
  WalletOutlined,
} from '@mui/icons-material';
import ProfileEditScreen from './profileEditComponent';

function DashboardHeader({
  image,
  handleHeaderTabClick,
  setEnableSideDrawer,
  enableSideDrawer,
  setTabValue,
  setIframeSrc,
}) {
  const dispatch = useDispatch();
  const { userInfo, notificationsCount, enableRequest } = useSelector(
    (state) => state.userInfo
  );
  const router = useRouter();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const [openLogoutConfirm, setOpenLogoutConfirm] = useState(false);
  const [enableEditProfile, setEnableEditProfile] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setEnableRequestHandler = (value) => {
    dispatch(setEnableRequest(value));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    setOpenLogoutConfirm(true);
    handleClose();
  };

  const handleLogoutConfirm = () => {
    // Add your logout logic here

    setOpenLogoutConfirm(false);
    dispatch(logOut());

    router.push('/');
  };

  const handleLogoutCancel = () => {
    setOpenLogoutConfirm(false);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {!isMobile && (
            <span style={{ flexGrow: 1, marginTop: '8px' }}>
              <img src="/chathive-w.png" width="100px" alt="mChatHive" />
            </span>
          )}

          <Stack direction="row" gap={1}>
            {/* <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('CHANNELS')}
            >
              CHANNELS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('CHANNELS')}
            >
              CLUBS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('MINIPROGRAMS')}
            >
              MINI PROGRAMS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('POOLS')}
            >
              POOLS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('EVENTS')}
            >
              EVENTS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('WALLET')}
            >
              <WalletOutlined />
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('EMAIL')}
            >
              <Email />
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('NOTIFICATIONS')}
            >
              <Notifications />
            </Button> */}
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: '120px', marginRight: '10px' }}
            >
              <IconButton
                onClick={() => {
                  setTabValue(5);
                  setIframeSrc('https://www.hootdex.net/');
                }}
              >
                <img
                  src="/hootdex_white.png"
                  alt="hootdex"
                  width="25px"
                  height="auto"
                />
              </IconButton>
              <IconButton
                variant="text"
                color="inherit"
                size="small"
                onClick={() => handleHeaderTabClick('WALLET')}
              >
                <WalletOutlined />
              </IconButton>
              <IconButton
                variant="text"
                size="small"
                color="inherit"
                onClick={() => {
                  handleHeaderTabClick('NOTIFICATIONS');
                  setEnableRequestHandler(true);
                }}
              >
                <Badge badgeContent={notificationsCount} color="error">
                  <Notifications />
                </Badge>
              </IconButton>
            </Stack>
            {isMobile && (
              <IconButton
                variant="outlined"
                color="inherit"
                onClick={() => setEnableSideDrawer((prev) => !prev)}
              >
                {enableSideDrawer ? <Close /> : <MenuIcon />}
              </IconButton>
            )}
          </Stack>

          <IconButton color="inherit" onClick={handleClick}>
            {image && (
              <Avatar
                src={image}
                style={{
                  backgroundColor: theme.palette.primary.light,
                }}
              />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Popup Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setEnableEditProfile(true);
          }}
        >
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            /* Add your logic for settings */
          }}
        >
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>

      {/* Logout Confirmation Modal */}
      <Dialog open={openLogoutConfirm} onClose={handleLogoutCancel}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to logout?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogoutConfirm} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {enableEditProfile && (
        <Dialog
          open={enableEditProfile}
          onClose={() => {
            setEnableEditProfile(false);
          }}
          maxWidth="md"
        >
          <ProfileEditScreen />
          {/* <IndividualPostOverlay postData={postData} /> */}
        </Dialog>
      )}
    </>
  );
}

export default DashboardHeader;
