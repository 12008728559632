import axios from 'axios';
import { format, isToday, isThisWeek } from 'date-fns';
import { toast } from 'react-toastify';

// count will be formatted
export const formatCount = (count) => {
  if (count < 1e3) return count;
  if (count >= 1e3 && count < 1e6) return +(count / 1e3).toFixed(1);
  if (count >= 1e6 && count < 1e9) return +(count / 1e6).toFixed(1);
  if (count >= 1e9 && count < 1e12) return +(count / 1e9).toFixed(1);
  if (count >= 1e12) return +(count / 1e12).toFixed(1);
};

// si stands for International System of Units
export const formatSi = (count) => {
  if (count < 1e3) return '';
  if (count >= 1e3 && count < 1e6) return 'K';
  if (count >= 1e6 && count < 1e9) return 'M';
  if (count >= 1e9 && count < 1e12) return 'B';
  if (count >= 1e12) return 'T';
};

export const getSecondaryText = (chatRoom) => {
  const { chat } = chatRoom || {};
  const { message } = chat || {};

  if (!message) return null;

  // File type regexes
  const IMAGE_REGEX = /\.(gif|png|jpg|jpeg|eps)$/i;
  const AUDIO_REGEX = /\.(mp3|wav)$/i;
  const VIDEO_REGEX = /\.(mp4|mov|wmv|webm|avi)$/i;
  const DOCUMENT_REGEX = /\.(pdf|doc|docx|xls|xlsx|txt)$/i;

  // Shared styles
  const sharedStyles = {
    width: '100%',
    borderRadius: '8px',
    maxWidth: '40px',
    maxHeight: '40px',
    objectFit: 'contain',
  };

  const secureMessage = message.replace('http://', 'https://');

  switch (true) {
    case IMAGE_REGEX.test(message):
      return (
        <img
          src={secureMessage}
          alt="Media content"
          style={sharedStyles}
          draggable={false}
        />
      );

    case AUDIO_REGEX.test(message):
      return (
        <audio src={secureMessage} controls={false} style={sharedStyles} />
      );

    case VIDEO_REGEX.test(message):
      return (
        <video
          src={secureMessage}
          controls={false}
          style={sharedStyles}
          draggable={false}
        />
      );

    case DOCUMENT_REGEX.test(message):
      return (
        <iframe
          title="ChatHive"
          src={secureMessage}
          style={{
            ...sharedStyles,
            maxHeight: '40px',
            width: 'fit-content',
          }}
          allowFullScreen
        />
      );

    default:
      // Truncate long text messages
      return message.length > 80 ? `${message.slice(0, 80)}...` : message;
  }
};

export const isAdmin = (username) =>
  ['louisvelazquez', 'Aakash', 'test1999'].includes(username);

export const socketEvents = {
  PRIVATE_MESSAGE_SOAPBOX: 'private-message-soapbox',
  PRIVATE_MESSAGE_SOAPBOX_REPLY: 'private-message-soapbox-reply',
  TYPING: 'typing',
  RECEIVE_PRIVATE_CHAT_SOAPBOX: 'receive-private-chat-soapbox',
  SEEN: 'seen',
};

export function copyTextToClipboard(text, message) {
  // Check if the Clipboard API is supported
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        console.log('Text copied to clipboard successfully!');
      })
      .catch(function (err) {
        console.error('Error copying text: ', err);
      });
  } else {
    // Fallback for older browsers
    let textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      console.log('Text copied to clipboard successfully!');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  }
  toast.success(message);
}

export function base64Encode(input) {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(input);
  return btoa(String.fromCharCode(...uint8Array));
}

export const getVideoPoster = (imageUrl) => {
  const imageUrlRaw = imageUrl.split('.')[0];
  return `https://soapboxapi.megahoot.net/audio-posters/${imageUrlRaw}.jpg`;
};

export const fetchFeed = async (page, setLoading, setFeedData, setHasMore) => {
  try {
    setLoading(true);
    const res = await axios.get(
      `https://soapboxapi.megahoot.net/upload/public/feeds/all?page=${page}&limit=3`
    );
    setFeedData((prevData) => [...prevData, ...res.data.results]);
    setHasMore(res.data.results.length > 0);
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

export const handleLike = async (hootId, setFeedData) => {
  try {
    await axios.put('https://soapboxapi.megahoot.net/upload/increment-like', {
      hootId,
    });
    setFeedData((prevData) =>
      prevData.map((post) =>
        post.hootId === hootId ? { ...post, likes: post.likes + 1 } : post
      )
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleRepost = async (postId, userInfo) => {
  if (window.confirm('Are you sure you want to repost this post?')) {
    try {
      await axios.post('https://soapboxapi.megahoot.net/upload/repost', {
        postId,
        username: userInfo.username,
        email: userInfo.email,
      });
      alert('Reposted from your timeline');
    } catch (error) {
      console.error(error);
    }
  }
};

export const formatDate = (date) => {
  const messageDate = new Date(date);
  if (isToday(messageDate)) {
    return format(messageDate, 'p'); // e.g., "12:34 PM"
  } else if (isThisWeek(messageDate)) {
    return format(messageDate, 'EEEE'); // e.g., "Monday"
  } else {
    return format(messageDate, 'MMM dd'); // e.g., "Jun 22"
  }
};
