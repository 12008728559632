import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Grid,
  useMediaQuery,
  Dialog,
} from '@mui/material';

import axios from 'axios';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Add, ArrowBack } from '@mui/icons-material';
import CreateChannel from './createChannel';
import ChannelModal from './individualChannel';
import { isAdmin } from '../../../utils';
import ChannelCard from './channelCard';

const ChannelChat = ({
  setShowLeft,
  setSelectedChat,
  setMessages,
  setPage,
  setTabValue,
}) => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelList, setChannelList] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [openChannel, setOpenChannel] = useState(false);
  const [searchText, setSearchtext] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchChannelList = () => {
    axios.get('https://soapboxapi.megahoot.net/channels').then((res) => {
      setChannelList(res.data.channels);
    });
  };

  const handleChannelClickHandler = (chatRoom) => {
    setSelectedChat({
      chatFrom: chatRoom.username,
      chatTo: userInfo.username,
      defaultRoomName: chatRoom.username,
      createdAt: '',
      fcmToken: '',
      name: chatRoom.username,
      profilePic: chatRoom.image,
      roomname: chatRoom.username,
      seen: 0,
      shared_public_key: '',
      threadId: '',
      chat: {
        defaultRoomName: chatRoom.username,
        profilePic: chatRoom.image,
        channelLogo: chatRoom.image,
      },
    });
    setMessages(null);
    setPage(1);
    setTabValue(0);
  };

  const handleChatRequest = (receivedData) => {
    axios
      .post('https://soapboxapi.megahoot.net/channels/join-request', {
        channelId: receivedData?.id,
        username: userInfo?.username,
      })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error('Something Went wrong, Try Again');
      });
  };

  const wrappedChannels = (searchText) => {
    if (searchText && searchText !== '') {
      return channelList?.filter(
        (e) => e.name?.includes(searchText) || e.username?.includes(searchText)
      );
    }
    return channelList;
  };

  useEffect(() => {
    // fetchChannelData();
    fetchChannelList();
  }, []);

  return (
    <Grid
      spacing={2}
      sx={{
        paddingY: 2,
        maxHeight: '95vh',
        overflow: 'auto',
        bgcolor: '#121820',
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: '#121820',
          marginTop: '-10px',
          padding: 1,
          mb: 5,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            bgcolor: '#121820',
            textAlign: 'center',
            flex: 0.7,
            fontWeight: 'bold',
          }}
          color="#fff"
        >
          {isMobile && (
            <IconButton
              onClick={() => {
                setShowLeft(true);
              }}
            >
              <ArrowBack />
            </IconButton>
          )}
          Channels
        </Typography>
        <TextField
          placeholder="Search"
          size="small"
          fullWidth
          sx={{
            flex: 0.3,

            borderRadius: '8px',
            bgcolor: '#171d26',
            marginBottom: '12px',
            input: { color: '#fff' }, // Input text color
            '& .MuiInputBase-input::placeholder': { color: '#fff' },
          }}
          onChange={(e) => {
            setSearchtext(e.target.value);
          }}
        />
      </Grid>
      <Grid container spacing={2} px={2}>
        {channelList &&
          wrappedChannels(searchText).map((app) => (
            <ChannelCard
              item={app}
              channelList={channelList}
              setSelectedChannel={setSelectedChannel}
              setOpenChannel={setOpenChannel}
              handleChannelClickHandler={handleChannelClickHandler}
              handleChatRequest={handleChatRequest}
            />
          ))}
      </Grid>
      <Button
        title="Create Mini Apps"
        sx={{
          backgroundColor: '#3d215b',
          color: '#fff',
          position: 'absolute',
          right: '50px',
          bottom: '50px',
          borderRadius: '100%',
          height: '60px',
          width: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setEnableEdit(true)}
      >
        <Add fontSize="medium" />
      </Button>

      <Dialog
        open={enableEdit}
        onClose={() => {
          setEnableEdit(false);
        }}
        maxWidth="md"
      >
        <CreateChannel
          setEnableEdit={setEnableEdit}
          fetchChannelList={fetchChannelList}
        />
      </Dialog>
      <ChannelModal
        openChannel={openChannel}
        setOpenChannel={setOpenChannel}
        selectedChannel={selectedChannel}
        userInfo={userInfo}
      />
    </Grid>
  );
};

export default ChannelChat;
