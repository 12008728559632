import {
  AddReaction,
  AttachFile,
  CloseOutlined,
  Mic,
  Send,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import MessageCard from '../messageCard';

function ChatComposer({
  showLeft,
  replyEnable,
  setReplyEnable,
  editingMessage,
  setEditingMessage,
  handleDeleteChat,
  editEnable,
  setEditEnable,
  handleSocketTyping,
  newMessage,
  setNewMessage,
  handleSocketEmit,
  isChatDisabled,
  setShowEmojiList,
  handleAttachClick,
  fileInputRef,
  handleFileChange,
}) {
  return (
    <CardContent
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '2px 0px 2px 0px',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#171d26',
        width: showLeft ? '59%' : '99%',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        {replyEnable && editingMessage && (
          <Box
            sx={{
              bgcolor: '#f0f0f0',
              padding: '1rem',
              width: '100%',
              position: 'relative',
            }}
          >
            <IconButton
              sx={{ position: 'absolute', right: '10px' }}
              onClick={() => {
                setReplyEnable(false);
                setEditingMessage(null);
              }}
            >
              <CloseOutlined color="error" />
            </IconButton>

            <MessageCard
              message={editingMessage}
              handleDeleteChat={handleDeleteChat}
              setEditingMessage={setEditingMessage}
              editingMessage={editingMessage}
              replyEnable={replyEnable}
              setReplyEnable={setReplyEnable}
              editEnable={editEnable}
              setEditEnable={setEditEnable}
            />
          </Box>
        )}

        <TextField
          onFocusCapture={() => handleSocketTyping()}
          fullWidth
          variant="outlined"
          size="small"
          sx={{
            marginLeft: '10px',
            input: { py: '1rem', color: '#fff' },
            bgcolor: '#121820',
            marginBottom: '12px',
            '& .MuiInputBase-input::placeholder': { color: '#fff' },
          }}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSocketEmit(newMessage);
          }}
          disabled={isChatDisabled()}
          placeholder={
            isChatDisabled()
              ? 'You can not send message anymore'
              : 'Type a message'
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  style={{ color: '#c2c2c2' }}
                  onClick={() => {
                    setShowEmojiList((prev) => !prev);
                  }}
                >
                  <AddReaction fontSize="small" />
                </IconButton>
                <IconButton
                  style={{ color: '#c2c2c2' }}
                  disabled={isChatDisabled()}
                >
                  <Mic />
                </IconButton>
                <IconButton
                  style={{ color: '#c2c2c2' }}
                  disabled={isChatDisabled()}
                  onClick={handleAttachClick}
                >
                  <AttachFile />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  style={{
                    color: '#fff',
                    backgroundColor: '#3d215b',
                    padding: '5px',
                  }}
                  variant="contained"
                  onClick={() => handleSocketEmit(newMessage)}
                  disabled={!newMessage.trim()}
                >
                  <Send />
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <input
          type="file"
          accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xls,.xlsx,.txt" // Accepts images, videos, audio, PDFs, and document files
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }} // Hide the file input
        />
      </Stack>
    </CardContent>
  );
}

export default ChatComposer;
