import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Avatar,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Collapse,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { toast } from 'react-toastify';

const CreateChannel = ({ setEnableEdit }) => {
  // eslint-disable-next-line no-unused-vars
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [website, setWebsite] = useState('');
  const [newProfile, setNewProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(
    'https://your-default-profile-pic-url.com'
  );

  const [showSocialLinks] = useState(false);

  // Social links states
  const [socialLinks, setSocialLinks] = useState({
    twitter: '',
    instagram: '',
    linkedIn: '',
    facebook: '',
    tiktok: '',
    snapchat: '',
    reddit: '',
    pinterest: '',
    medium: '',
    tumblr: '',
  });

  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    setSocialLinks((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    if (!username || !name) {
      return false;
    }
    return true;
  };

  const CreateChannelAPI = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append('name', name);
    formData.append('username', username);
    formData.append('link', website);
    formData.append('createdBy', userInfo?.username);
    formData.append('owner', userInfo?.username);
    formData.append('description', bio);
    formData.append('status', 'Active');
    formData.append('members', JSON.stringify([userInfo?.username])); // Set the initial creator as member

    if (newProfile) {
      formData.append('file', newProfile);
    }

    // Create the channel function
    const createChannel = async () => {
      try {
        const result = await axios.post(
          'https://soapboxapi.megahoot.net/channels/create',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (result.data.success) {
          setLoading(false);
          setEnableEdit(false);

          return `${name} Channel Created successfully`; // Return success message
        } else {
          throw new Error(result.data.message || 'Failed to create channel');
        }
      } catch (error) {
        setLoading(false);
        // Return the error message for toast to handle
        return Promise.reject(error.message || 'Failed to create channel');
      }
    };

    // Wrap the createChannel function in toast.promise
    toast.promise(createChannel, {
      pending: 'Creating Channel...',
      success: 'Channel Created Successfully', // Success message is returned from createChannel
      error: 'Failed to create channel', // Error message is returned from createChannel's catch block
    });
  };

  const handleSubmit = () => {
    if (validate()) {
      CreateChannelAPI();
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: '0 auto',
        padding: 3,
        bgcolor: '#f9f9f9',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Create Channel
      </Typography>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Avatar
          src={profilePic}
          alt="Profile"
          sx={{ width: 100, height: 100, margin: '0 auto', mb: 1 }}
        />
        <input
          accept="image/*"
          id="file-input"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setProfilePic(URL.createObjectURL(file));
              setNewProfile(file);
            }
          }}
        />
        <label htmlFor="file-input">
          <IconButton component="span" color="primary">
            <PhotoCamera />
          </IconButton>
        </label>
      </Box>
      <Box
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <TextField
          label="Channel Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Channel username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          label="Description"
          variant="outlined"
          multiline
          minRows={3}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
        <TextField
          label="Link"
          variant="outlined"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />

        <Collapse in={showSocialLinks}>
          {Object.keys(socialLinks).map((link) => (
            <TextField
              key={link}
              label={link.charAt(0).toUpperCase() + link.slice(1)}
              name={link}
              variant="outlined"
              value={socialLinks[link]}
              onChange={handleSocialChange}
              sx={{ mt: 2 }}
            />
          ))}
        </Collapse>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Create Channel'}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateChannel;
