import {
  CodeOutlined,
  Delete,
  MoreVert,
  ShareOutlined,
  Visibility,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MarkdownPreview from '@uiw/react-markdown-preview';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';
import PublicProfilePage from './publicProfilePage';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import {
  base64Encode,
  copyTextToClipboard,
  formatCount,
  formatSi,
} from '../../utils';
import UserBadge from './userBadge';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import FeedContent from './feeds/feedContent';

function IndividualPostOverlay({ postData, setPosts, forceClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [postDataStyles, setPostDataStyles] = useState(null);
  const [expandProfile, setExpandProfile] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const { token, userInfo } = useSelector((state) => state.userInfo);

  const deleteHandler = (postData) => {
    // Show a confirmation prompt
    const userConfirmed = window.confirm(
      'Are you sure you want to delete this post?'
    );

    if (userConfirmed) {
      // If the user confirms, call the handleDelete function to delete the post
      handleDelete(postData.id);
    } else {
      // If the user cancels, show a cancellation toast or simply do nothing
      toast.info('Post deletion cancelled.');
    }
  };

  // Handle the delete action
  const handleDelete = async (postId) => {
    try {
      // Send DELETE request to the API to delete the post
      const response = await axios.delete(
        `https://soapboxapi.megahoot.net/upload/post/${postId}`
      );
      if (response.data.message === 'Post deleted successfully!') {
        toast.success('Post deleted successfully!');
        // Optionally, update your UI by removing the deleted post from the state
        // For example, if you're managing posts in state, you can filter it out:
        setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
        forceClose();
      } else {
        toast.error('Failed to delete post. Please try again.');
      }
    } catch (error) {
      toast.error('Error deleting post. Please try again later.');
    }
  };

  useEffect(() => {
    if (postData) {
      const fontFamilyStyle =
        postData?.fontFamilyStyle && postData.fontFamilyStyle.includes('{')
          ? JSON.parse(postData.fontFamilyStyle)
          : null;

      setPostDataStyles(fontFamilyStyle);
    }
  }, [postData]);

  return (
    <Box
      id={`feed-item-${postData?.id}`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        margin: '0 auto',
        position: 'relative',
        backgroundColor: '#000',
        padding: '1px',
        justifyContent: 'center',
      }}
    >
      {postData?.mimeType ? (
        <Card
          sx={{
            width: '432px',
            backgroundColor: '#000',
            height: '85vh',
            border: '1px solid grey',
            borderRight: 'none',
          }}
          elevation={50}
        >
          {postData?.mimeType?.includes('video') ? (
            <>
              <CardMedia
                component="video"
                controlsList="nodownload" // Remove download option
                loading="lazy"
                src={`https://soapboxapi.megahoot.net/images/${postData.image}`}
                poster={`https://soapboxapi.megahoot.net/images/${postData.audioPoster}`}
                controls={true}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  bgcolor: '#1e1e1e',
                }}
                muted={true}
                autoPlay={true}
              />
            </>
          ) : postData?.mimeType?.includes('audio') ? (
            <>
              <CardMedia
                component="video"
                controlsList="nodownload" // Remove download option
                loading="lazy"
                src={`https://soapboxapi.megahoot.net/images/${postData.image}`}
                poster={`/audio.png`}
                controls={true}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  bgcolor: '#1e1e1e',
                }}
                muted={true}
              />
            </>
          ) : postData?.mimeType?.includes('image') ? (
            <CardMedia
              component="img"
              loading="lazy"
              image={`https://soapboxapi.megahoot.net/images/${postData.image}`}
              alt="feed"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                bgcolor: '#1e1e1e',
              }}
            />
          ) : null}
        </Card>
      ) : (
        <Card
          sx={{
            width: '432px',
            backgroundColor: postData?.mimeType ? '' : postDataStyles?.bgColor,
            height: '85vh',
            border: '1px solid grey',
            borderRight: 'none',
          }}
          elevation={50}
        >
          <MarkdownPreview
            source={postData.caption}
            style={
              postDataStyles
                ? {
                    all: 'unset',
                    padding: 4,
                    wordWrap: 'break-word',
                    width: '100%',
                    overflow: 'auto',
                    color: postDataStyles?.color,
                    backgroundColor: postDataStyles?.bgColor,
                    height: '100%',
                  }
                : {
                    all: 'unset',
                    padding: 4,
                    wordWrap: 'break-word',
                    width: '100%',
                    overflow: 'auto',
                    color: '#fff',
                    backgroundColor: '#000',
                    height: '100%',
                  }
            }
          />
        </Card>
      )}

      <FeedContent
        hoot={postData}
        setFeedData={setPosts}
        deleteHandler={deleteHandler}
      />

      <Dialog
        maxWidth="xl"
        open={expandProfile}
        fullWidth={true}
        onClose={() => setExpandProfile(false)}
      >
        <PublicProfilePage
          username={postData?.authorUsername}
          isChannel={false}
          ChannelRoomName={null}
        />
      </Dialog>
    </Box>
  );
}

export default IndividualPostOverlay;
