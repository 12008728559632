import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import { useTheme } from '@emotion/react';
import { Avatar, Dialog, Stack, TextField, useMediaQuery } from '@mui/material';
import { Add, ArrowBack, Create } from '@mui/icons-material';
import CreateMiniApps from '../createMiniApps';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isAdmin } from '../../../utils';

const apps = [
  {
    name: 'The Pecu Wallet: Crypto & Defi',
    image: '/pecu.png',
    provider: 'MegaHoot Technologies',
    link: 'PECU_PinScreen',
    url: 'https://pecunovus.net/',
  },
  {
    name: 'HootDex : Crypto Swapping',
    image: '/hd.webp',
    provider: 'MegaHoot Technologies',
    link: 'HD_Screen',
    url: 'https://www.hootdex.net/',
  },
];

const MiniProgramsCatalogue = ({
  setTabValue,
  setIframeSrc,
  iframeSrc,
  setShowLeft,
}) => {
  const theme = useTheme();
  const renderItem = (item) => (
    <Grid item xs={12} md={6}>
      <Card
        sx={{
          backgroundColor: '#171d26',
          padding: 2,
          borderRadius: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            px: 0,
            alignItems: 'center',
          }}
        >
          <Avatar
            src={`https://soapboxapi.megahoot.net/images/${item?.image}`}
            alt={item?.name}
            sx={{ ml: 1, height: '50px', width: '50px', mr: 1 }}
          />
          <Stack>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {item?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: '#c2c2c2' }}>
              {item.company}
            </Typography>
          </Stack>
        </Stack>
        <Grid spacing={2}>
          <Button
            color="primary"
            variant="contained"
            // sx={{ backgroundColor: '#03875f' }}
            onClick={() => {
              setTabValue(5);
              setIframeSrc(item.link);
            }}
          >
            Open
          </Button>
          <Button
            variant="text"
            sx={{ color: '#c2c2c2' }}
            // sx={{ color: '#03875f', borderColor: '#03875f' }}

            // onClick={() => shareMiniApp(item.name)}
            endIcon={<ShareIcon />}
          />
        </Grid>
      </Card>
    </Grid>
  );
  const [miniApps, setMiniApps] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [enableEdit, setEnableEdit] = useState(false);
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const [searchText, setSearchtext] = useState('');
  const wrappedApps = (searchText) => {
    if (searchText && searchText !== '') {
      return miniApps?.filter(
        (e) => e.name?.includes(searchText) || e.company?.includes(searchText)
      );
    }
    return miniApps;
  };
  const fetchAllMiniApps = () => {
    axios.get(`https://soapboxapi.megahoot.net/miniapps`).then((res) => {
      if (res.data && res.data.miniApps) {
        setMiniApps(res.data.miniApps);
      }
    });
  };

  useEffect(() => {
    fetchAllMiniApps();
  }, []);

  return (
    <Grid
      spacing={2}
      sx={{
        paddingY: 2,
        maxHeight: '95vh',
        overflow: 'auto',
        bgcolor: '#121820',
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: '#121820',
          marginTop: -1.5,
          padding: 1,
          mb: 5,
        }}
      >
        <TextField
          placeholder="Search"
          size="large"
          fullWidth
          onChange={(e) => {
            setSearchtext(e.target.value);
          }}
          sx={{
            bgcolor: '#171d26',
            marginBottom: '12px',
            input: { color: '#fff' }, // Input text color
            '& .MuiInputBase-input::placeholder': { color: '#fff' }, // Placeholder text color
          }}
        />
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          {isMobile && (
            <IconButton
              onClick={() => {
                setShowLeft(true);
              }}
            >
              <ArrowBack />
            </IconButton>
          )}{' '}
          Explore Apps
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: 'center',
            color: '#fff',
            fontWeight: 'bold',
            marginTop: '10px',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ fontSize: '18px', marginRight: '10px', fontWeight: 'bold' }}
          >
            Built For
          </Typography>
          mChatHive
        </Typography>
      </Grid>
      <Grid container spacing={2} px={2}>
        {miniApps && wrappedApps(searchText).map((app) => renderItem(app))}
      </Grid>
      {isAdmin(userInfo?.username) && (
        <Button
          title="Create Mini Apps"
          sx={{
            backgroundColor: '#3d215b',
            color: '#fff',
            position: 'absolute',
            right: '50px',
            bottom: '50px',
            borderRadius: '100%',
            height: '60px',
            width: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setEnableEdit(true)}
        >
          <Add fontSize="medium" />
        </Button>
      )}

      <Dialog
        open={enableEdit}
        onClose={() => {
          setEnableEdit(false);
        }}
        maxWidth="md"
      >
        <CreateMiniApps
          setEnableEdit={setEnableEdit}
          fetchAllMiniApps={fetchAllMiniApps}
        />
      </Dialog>
    </Grid>
  );
};

export default MiniProgramsCatalogue;
