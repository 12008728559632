import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Grid,
  Box,
  Tabs,
  Tab,
  Badge,
  useMediaQuery,
  Dialog,
  Button,
  Stack,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import ChatList from './ChatList';
import ContactList from './ContactList';
import RequestList from './RequestList';
import MobileView from './MobileView';
import SideDrawer from './SideDrawer';
import TabList from './TabList';
import {
  setEnableRequest,
  setNotificationsData,
} from '../../../redux/reducers/userSlice';

function LeftPanel({
  chatListData,
  contactsData,
  setSelectedChat,
  selectedChat,
  setPage,
  setMessages,
  tabValue,
  setTabValue,
  handleHeaderTabClick,
  fetchContacts,
  setShowLeft,
  enableSideDrawer,
}) {
  const theme = useTheme();
  const { userInfo, enableRequest } = useSelector((state) => state.userInfo);
  const [requestsData, setRequestsData] = useState(null);
  const [requestCount, setRequestCount] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchAllRequests(userInfo);
    fetchContacts(userInfo?.username);
  };

  const setEnableRequestHandler = (value) => {
    dispatch(setEnableRequest(value));
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const fetchAllRequests = async (userInfo) => {
    try {
      const [channelResponse, messageResponse] = await Promise.all([
        axios.get(
          `https://soapboxapi.megahoot.net/channels/view-requests/${userInfo?.username}`
        ),
        axios.get(
          `https://soapboxapi.megahoot.net/upload/userRequests?userId=${userInfo?.username}`
        ),
      ]);

      const channelRequests = channelResponse.data.requests.map((item) => ({
        ...item,
        type: 'channel',
      }));
      const messageRequests = messageResponse.data.requests.map((item) => ({
        ...item,
        type: 'message',
      }));

      const allRequestData = [...channelRequests, ...messageRequests];
      const activeChannelReq = channelRequests?.filter(
        (e) => e.status === 'Pending'
      );
      const activeMessageReq = messageRequests?.filter(
        (e) => e.status === 'pending'
      );
      const allCount = activeChannelReq.length + activeMessageReq.length;
      setRequestsData(allRequestData);

      const payload = {
        notificationsCount: allCount,
        notifications: allRequestData,
      };

      dispatch(setNotificationsData(payload));
      setRequestCount(allCount);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveRequest = (request) => {
    if (request.type === 'message') {
      const messageRequest = async () => {
        await axios.post(
          `https://soapboxapi.megahoot.net/upload/approveMessageRequest`,
          {
            requestId: request.id,
          }
        );
        fetchAllRequests(userInfo);
      };

      const from =
        request?.from === userInfo?.username ? request.to : request.from;
      toast.promise(messageRequest, {
        pending: `Approving message request of ${from}`,
        success: `Message request of ${from} is approved successfully`,
        error: 'Please try again',
      });
    } else {
      const messageRequest = async () => {
        await axios.put(
          `https://soapboxapi.megahoot.net/channels/handle-request/${request.requestId}`,
          {
            status: 'Approved',
          }
        );
        fetchAllRequests(userInfo);
      };

      toast.promise(messageRequest, {
        pending: `Approving channel request `,
        success: `Channel request is approved successfully`,
        error: 'Please try again',
      });
    }
  };

  const handleDeleteRequest = (request) => {
    if (request.type === 'message') {
      const messageRequest = async () => {
        await axios.post(
          `https://soapboxapi.megahoot.net/upload/deleteRequest`,
          {
            requestId: request.id,
          }
        );
        fetchAllRequests(userInfo);
      };

      const from =
        request?.from === userInfo?.username ? request.to : request.from;
      toast.promise(messageRequest, {
        pending: `Deleting message request of ${from}`,
        success: `Message request to ${from} is deleted successfully`,
        error: 'Please try again',
      });
    } else {
      const messageRequest = async () => {
        await axios.put(
          `https://soapboxapi.megahoot.net/channels/handle-request/${request.requestId}`,
          {
            status: 'Rejected',
          }
        );
        fetchAllRequests(userInfo);
      };

      toast.promise(messageRequest, {
        pending: `Deleting channel request`,
        success: `Channel request is deleted successfully`,
        error: 'Please try again',
      });
    }
  };

  useEffect(() => {
    if (enableRequest && userInfo) {
      fetchAllRequests(userInfo);
    }
  }, [enableRequest, userInfo]);
  useEffect(() => {
    if (userInfo) {
      fetchAllRequests(userInfo);
    }
  }, [userInfo]);
  return (
    <Grid>
      <Card
        style={{
          height: isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 64px)',
          borderRadius: 0,
          backgroundColor: '#121820',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row', // Align items horizontally,
          color: '#fff',
          padding: '20px',
        }}
      >
        <TabList
          setTabValue={setTabValue}
          setShowLeft={setShowLeft}
          setSelectedChat={setSelectedChat}
          tabValue={tabValue}
          handleHeaderTabClick={handleHeaderTabClick}
          requestCount={requestCount}
          setEnableRequest={setEnableRequestHandler}
        />

        <CardContent
          style={{
            paddingBottom: 16,
            flexGrow: 1, // This will make the list grow to fill the available space
            overflowY: 'auto', // Allows scrolling if the chat list is too long
            color: '#fff',
          }}
        >
          <Stack
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              color: '#fff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}
          >
            <Button
              sx={{
                flex: 1,
                color: value === 0 ? '#fff' : '#c2c2c2',
                padding: 1.1,
              }}
              onClick={(e) => handleChange(e, 0)}
              size="large"
              variant={value === 0 ? 'contained' : ''}
            >
              Messages
            </Button>
            <Button
              size="large"
              sx={{
                flex: 1,
                color: value === 1 ? '#fff' : '#c2c2c2',
                padding: 1.1,
              }}
              onClick={(e) => handleChange(e, 1)}
              variant={value === 1 ? 'contained' : ''}
            >
              Contacts
            </Button>
          </Stack>
          <TextField
            fullWidth
            placeholder="Search"
            size="small"
            sx={{
              bgcolor: '#171d26',
              marginBottom: '12px',
              input: { color: '#fff' }, // Input text color
              '& .MuiInputBase-input::placeholder': { color: '#fff' }, // Placeholder text color
            }}
          />
          <ChatList
            value={value}
            chatListData={chatListData}
            setSelectedChat={setSelectedChat}
            selectedChat={selectedChat}
            setMessages={setMessages}
            setPage={setPage}
            setTabValue={setTabValue}
            setShowLeft={setShowLeft}
          />

          <ContactList
            value={value}
            contactsData={contactsData}
            setSelectedChat={setSelectedChat}
            setMessages={setMessages}
            setPage={setPage}
            setTabValue={setTabValue}
            setShowLeft={setShowLeft}
          />
          <RequestList
            expandedViewHandler={() => setEnableRequestHandler(true)}
            requestsData={requestsData}
            handleDeleteRequest={handleDeleteRequest}
            handleApproveRequest={handleApproveRequest}
          />
        </CardContent>

        <MobileView
          setTabValue={setTabValue}
          setShowLeft={setShowLeft}
          setSelectedChat={setSelectedChat}
          tabValue={tabValue}
          handleHeaderTabClick={handleHeaderTabClick}
        />
        {/* Vertical Navigation */}
        <SideDrawer
          enableSideDrawer={enableSideDrawer}
          setTabValue={setTabValue}
          setShowLeft={setShowLeft}
          setSelectedChat={setSelectedChat}
          tabValue={tabValue}
          handleHeaderTabClick={handleHeaderTabClick}
          requestCount={requestCount}
        />

        <Dialog
          maxWidth="xs"
          open={enableRequest}
          fullWidth={true}
          onClose={() => setEnableRequestHandler(false)}
        >
          <RequestList
            value={2}
            expandedViewDefault={true}
            requestsData={requestsData}
            handleDeleteRequest={handleDeleteRequest}
            handleApproveRequest={handleApproveRequest}
          />
        </Dialog>
      </Card>
    </Grid>
  );
}

export default LeftPanel;
