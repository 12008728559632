import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import Footer from './footer';
import Header from './header';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { addToken, updateUser } from '../redux/reducers/userSlice';
import { useRouter } from '../components/hooks';
const baseUrl = `https://soapboxapi.megahoot.net`;
const Login = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);

  const fetchuserData = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/user/${username}`)
      .then((res) => {
        // setUser(res.data[0]);
        if (res.data) {
          dispatch(updateUser(res.data[0]));
          router.push('/home');
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    setLoading(true);

    axios
      .post('https://soapboxapi.megahoot.net/api/v2/auth/login', {
        email: email,
        password: password,
      })
      .then((res) => {
        const { loggedIn, token, user, message } = res.data;
        if (loggedIn) {
          const payload = { loggedIn, ...user, message };
          localStorage.setItem('mchathive-auth-token-v2', token);
          localStorage.setItem('mchathive-protocol-v2-onboarded', true);
          dispatch(addToken(JSON.stringify(payload)));
          // saveUser(JSON.stringify(res.data));
          fetchuserData(user.username);
          setLoading(false);
        } else {
          alert(message);
          setLoading(false);
        }
      })
      .catch((err) => alert(err.message || 'Please try again'))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const isOnBordedToV2 = localStorage.getItem(
      'mchathive-protocol-v2-onboarded'
    );
    if (isOnBordedToV2) {
      const jwtToken = localStorage.getItem('mchathive-auth-token-v2');
      if (jwtToken) {
        const userData = jwtDecode(jwtToken);
        const { username } = userData;
        fetchuserData(username);
      }
    } else {
      const existingTokenraw = localStorage.getItem('mchathive-auth-token');
      if (existingTokenraw && existingTokenraw !== '') {
        const userData = JSON.parse(existingTokenraw);
        fetchuserData(userData.username);
      }
    }
  }, []);
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      style={{
        minHeight: '100vh',
        background: 'url(/bg.png)',
        padding: '20px',
      }}
    >
      <Header />
      <Grid
        container
        sx={{
          backgroundColor: '#ffffff1f',
          boxShadow: `3px 9px 16px hsla(0,1%,59%,.4),-3px -3px 10px hsla(0,0%,100%,.06),inset 14px 14px 26px rgba(0,0,0,.3),inset -3px -3px 15px hsla(0,9%,79%,.05)`,
        }}
        justifyContent="space-between" // Center the form when the image is hidden
        alignItems="center"
        xs={12}
        sm={10} // Half width on medium and large screens
        md={10} // 4/12 width on medium screens
        borderRadius="20px"
      >
        {/* Left Side: Poster Image - Hide on small screens */}
        <Grid
          item
          sm={6} // Display image on small screens and above
          md={6} // Medium and large screens
          display={{ xs: 'none', sm: 'block' }} // Hide image on extra-small screens (xs)
          justifyContent="flex-start"
        >
          <img
            src="/ch_poster.png"
            alt="Poster"
            style={{
              width: '100%',
              maxWidth: '700px',
              height: 'auto',
            }}
          />
        </Grid>

        {/* Right Side: Login Form */}
        <Grid
          item
          xs={12} // Full width on small screens
          sm={6} // Half width on medium and large screens
          md={4} // 4/12 width on medium screens
          padding="1rem"
        >
          <Card
            style={{
              minHeight: '60vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              {/* Center the logo */}
              <Box display="flex" justifyContent="center" mb={2}>
                <img
                  src="/chathive-p.png"
                  alt="chathive"
                  width="100px"
                  height="auto"
                />
              </Box>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                align="center"
              >
                Login
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
                <Box textAlign="center" mb={2}>
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Login {loading && <CircularProgress />}
                  </Button>
                </Box>
                <Box textAlign="center">
                  <Typography variant="body2">
                    Don't have an account? <a href="/signup">Sign up</a>
                  </Typography>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Login;
