import { useTheme } from '@emotion/react';
import {
  AddCircle,
  AppsOutlined,
  EmailOutlined,
  GroupOutlined,
  HomeOutlined,
  Notifications,
  OndemandVideoOutlined,
  SearchOutlined,
  SpeakerGroupOutlined,
  WalletOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  BottomNavigationAction,
  Box,
  Card,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function SideDrawer({
  enableSideDrawer,
  setTabValue,
  setShowLeft,
  setSelectedChat,
  tabValue,
  handleHeaderTabClick,
  requestCount,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { userInfo } = useSelector((state) => state.userInfo);
  return (
    enableSideDrawer && (
      <Card
        style={{
          paddingBottom: 16,
          flexGrow: 1, // This will make the list grow to fill the available space
          overflowY: 'auto', // Allows scrolling if the chat list is too long
          height: '100vh',
          position: 'absolute',
          left: '0px',

          width: 'fit-content',
          top: '-8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Align navigation items vertically
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            padding: '16px 0',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <BottomNavigationAction
            label="Dashboard"
            showLabel={true}
            icon={<HomeOutlined />}
            onClick={() => {
              setTabValue(0);
              if (isMobile) {
                setShowLeft(false);
              }
              setSelectedChat(null);
            }}
            selected={tabValue === 0}
          />
          <BottomNavigationAction
            label="Post"
            showLabel={true}
            icon={<AddCircle sx={{ fontSize: 40 }} color="primary" />}
            onClick={() => handleHeaderTabClick('CREATEPOST')}
            selected={tabValue === 2}
          />
          <BottomNavigationAction
            label="Discover"
            showLabel={true}
            icon={<SearchOutlined />}
            onClick={() => {
              setTabValue(1);
              if (isMobile) {
                setShowLeft(false);
              }
            }}
            selected={tabValue === 1}
          />

          <BottomNavigationAction
            label="Soapbox"
            showLabel={true}
            icon={<OndemandVideoOutlined />}
            onClick={() => {
              setTabValue(3);
              if (isMobile) {
                setShowLeft(false);
              }
            }}
            selected={tabValue === 3}
          />
          <BottomNavigationAction
            label="Mega Mail"
            showLabel={true}
            icon={<EmailOutlined />}
            onClick={() => handleHeaderTabClick('EMAIL')}
          />
          <BottomNavigationAction
            label="Wallet"
            showLabel={true}
            icon={<WalletOutlined />}
            onClick={() => handleHeaderTabClick('WALLET')}
          />
          <BottomNavigationAction
            label="Channels"
            showLabel={true}
            icon={<GroupOutlined />}
            onClick={() => handleHeaderTabClick('CHANNELS')}
          />
          <BottomNavigationAction
            label="Clubs"
            showLabel={true}
            icon={<SpeakerGroupOutlined />}
            onClick={() => handleHeaderTabClick('CHANNELS')}
          />
          <BottomNavigationAction
            label="Mini Apps"
            showLabel={true}
            icon={<AppsOutlined />}
            onClick={() => handleHeaderTabClick('MINIPROGRAMS')}
          />
          <BottomNavigationAction
            label={`Notifications`}
            showLabel={true}
            icon={
              <Badge badgeContent={requestCount} color="error">
                <Notifications />
              </Badge>
            }
            onClick={() => handleHeaderTabClick('NOTIFICATIONS')}
          />
          <BottomNavigationAction
            label="Me"
            icon={
              <Avatar
                src={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
                style={{
                  backgroundColor: theme.palette.primary.light,
                  width: '30px',
                  height: '30px',
                }}
              />
            }
            onClick={() => {
              setTabValue(4);
              if (isMobile) {
                setShowLeft(false);
              }
            }}
            selected={tabValue === 4}
          />
        </Box>
      </Card>
    )
  );
}

export default SideDrawer;
