import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Grid,
  CardContent,
  Avatar,
  Typography,
  Box,
  Button,
  Stack,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Delete } from '@mui/icons-material';
import EditChannel from './editChannel';

const ChannelModal = ({
  openChannel,
  setOpenChannel,
  selectedChannel,
  userInfo,
}) => {
  const [showAddInput, setShowAddInput] = useState(false);
  const [showRemoveInput, setShowRemoveInput] = useState(false);
  const [newMember, setNewMember] = useState('');
  const [removeMember, setRemoveMember] = useState('');
  const [allMembers, setAllMembers] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const handleAddMember = async () => {
    if (!newMember.trim()) return;
    try {
      await axios.put(
        `https://soapboxapi.megahoot.net/channels/add-member/${selectedChannel.id}`,
        {
          username: newMember,
        }
      );
      toast.success(`${newMember} added successfully`);
      setNewMember('');
      setShowAddInput(false);
      setAllMembers((prev) => {
        return [...prev, newMember];
      });
    } catch (error) {
      toast.error('Error adding member. Please try again.');
    }
  };

  const handleRemoveMember = async (username) => {
    try {
      await axios.put(
        `https://soapboxapi.megahoot.net/channels/remove-member/${selectedChannel.id}`,
        {
          username,
        }
      );
      toast.success(`${username} removed successfully`);
      setAllMembers((prev) => prev.filter((e) => e !== username));
    } catch (error) {
      toast.error('Error removing member. Please try again.');
    }
  };

  useEffect(() => {
    const allMembersData = selectedChannel?.members
      ? JSON.parse(selectedChannel?.members)
      : [];
    setAllMembers(allMembersData);
  }, [selectedChannel]);

  return (
    <Dialog
      open={openChannel}
      onClose={() => setOpenChannel(false)}
      maxWidth="lg"
      fullWidth
    >
      <Paper>
        <Grid container alignItems="center" sx={{ p: 3 }}>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item>
                <Avatar
                  src={`http://soapboxapi.megahoot.net/profile-pictures/${
                    selectedChannel?.profilePic ?? selectedChannel?.image
                  }`}
                  alt={selectedChannel?.name}
                  sx={{ height: '60px', width: '60px' }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {selectedChannel?.name}
                </Typography>
                <Typography color="textSecondary">
                  @{selectedChannel?.username}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              {/* <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  px: 5,
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  mb: 5,
                }}
              >
                Go to ChatRoom
              </Button> */}
              {/* {selectedChannel?.owner !== userInfo?.username && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    px: 5,
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    mb: 5,
                    mx: 2,
                  }}
                >
                  Request to Join
                </Button>
              )} */}

              {(selectedChannel?.owner === userInfo?.username ||
                selectedChannel?.createdBy === userInfo?.username) && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    px: 5,
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    mb: 5,
                    mx: 2,
                  }}
                  onClick={() => {
                    setEnableEdit(true);
                  }}
                >
                  Edit Channel
                </Button>
              )}
              <Typography variant="body1" sx={{ mb: 1 }}>
                {selectedChannel?.description}
              </Typography>

              <Stack spacing={1}>
                {(selectedChannel?.owner === userInfo?.username ||
                  selectedChannel?.createdBy === userInfo?.username) && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Moderator:</strong> {selectedChannel?.owner}
                    </Typography>
                  </>
                )}
                {selectedChannel?.createdBy === userInfo?.username && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Created By:</strong> {selectedChannel?.createdBy}
                    </Typography>
                  </>
                )}

                <Typography variant="body2" color="textSecondary">
                  <strong>Total Members:</strong>
                  {selectedChannel?.members
                    ? JSON.parse(selectedChannel?.members).length
                    : 0}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ mt: 4 }}>
              {(selectedChannel?.owner === userInfo?.username ||
                selectedChannel?.createdBy === userInfo?.username) && (
                <>
                  <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                    Manage Members
                  </Typography>
                  <Stack spacing={2} sx={{ mb: 2 }} alignItems="flex-start">
                    {/* Add Member Section */}
                    {!showAddInput && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => setShowAddInput(true)}
                      >
                        Add New Member
                      </Button>
                    )}
                    {showAddInput && (
                      <>
                        <TextField
                          label="Add Member by Username"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => setNewMember(e.target.value)}
                          value={newMember}
                        />
                        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={handleAddMember}
                          >
                            Add
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setShowAddInput(false)}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </>
                    )}

                    {!showRemoveInput && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setShowRemoveInput(true)}
                      >
                        Remove a Member
                      </Button>
                    )}
                    {/* Remove Member Section */}
                    {showRemoveInput && (
                      <>
                        <TextField
                          label="Remove Member by Username"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => setRemoveMember(e.target.value)}
                          value={removeMember}
                        />
                        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleRemoveMember(removeMember)}
                          >
                            Remove
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setShowRemoveInput(false)}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Stack>
                  <Typography variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                    Member List
                  </Typography>

                  <List style={{ display: 'flex', flexDirection: 'row' }}>
                    {allMembers.map((member, idx) => (
                      <ListItem
                        key={idx}
                        style={{
                          border: '1px solid #f0f0f0',
                          padding: '6px',
                          minWidth: '180px',
                          maxWidth: '220px',
                          borderRadius: '8px',
                          margin: '0 5px 0 5px',
                        }}
                      >
                        <Avatar
                          src=""
                          alt={member}
                          sx={{ marginRight: '10px' }}
                        />
                        <ListItemText primary={member} />
                        {member !== userInfo?.username && (
                          <IconButton
                            onClick={() => handleRemoveMember(member)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Box>
          </CardContent>
        </Grid>
      </Paper>

      <Dialog
        open={enableEdit}
        onClose={() => {
          setEnableEdit(false);
        }}
        maxWidth="md"
      >
        <EditChannel setEnableEdit={setEnableEdit} channel={selectedChannel} />
      </Dialog>
    </Dialog>
  );
};

export default ChannelModal;
