import { Box, Card, CardMedia } from '@mui/material';
import React from 'react';
import FeedContent from './feedContent';

function FeedCard({
  item,
  index,
  setOpenPost,
  setPostData,
  observerRefs,
  videoRefs,
  togglePlayPause,
  setFeedData,
}) {
  return (
    <Box
      key={index}
      id={`feed-item-${index}`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        margin: '0 auto',
        my: 5,
        position: 'relative',
        backgroundColor: '#171d26',
        padding: '20px',
        justifyContent: 'center',
        borderRadius: '12px',
      }}
      ref={(el) => (observerRefs.current[index] = el)}
    >
      <Card
        onClick={() => {
          setOpenPost(true);
          setPostData(item);
        }}
        sx={{
          width: '432px',
          backgroundColor: '#000',
          borderTopLeftRadius: '20px',
          borderBottomLeftRadius: '20px',
          height: '85vh',
          border: '1px solid grey',
          borderRight: 'none',
        }}
        elevation={50}
      >
        {item.mimeType.includes('video') ? (
          <>
            <CardMedia
              component="video"
              controlsList="nodownload" // Remove download option
              loading="lazy"
              src={`https://soapboxapi.megahoot.net/images/${item.image}`}
              poster={`https://soapboxapi.megahoot.net/images/${item.audioPoster}`}
              ref={(el) => (videoRefs.current[index] = el)}
              controls={true}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                bgcolor: '#1e1e1e',
              }}
              muted={true}
              onClick={() => togglePlayPause(index)}
            />
          </>
        ) : item.mimeType.includes('audio') ? (
          <>
            <CardMedia
              component="video"
              controlsList="nodownload" // Remove download option
              loading="lazy"
              src={`https://soapboxapi.megahoot.net/images/${item.image}`}
              poster={`/audio.png`}
              ref={(el) => (videoRefs.current[index] = el)}
              controls={true}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                bgcolor: '#1e1e1e',
              }}
              muted={true}
              onClick={() => togglePlayPause(index)}
            />
          </>
        ) : item.mimeType.includes('image') ? (
          <CardMedia
            component="img"
            loading="lazy"
            image={`https://soapboxapi.megahoot.net/images/${item.image}`}
            alt="feed"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              bgcolor: '#1e1e1e',
            }}
          />
        ) : null}
      </Card>
      <FeedContent hoot={item} setFeedData={setFeedData} />
    </Box>
  );
}

export default FeedCard;
