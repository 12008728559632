import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Avatar,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Collapse,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { toast } from 'react-toastify';
import { updateUser } from '../../redux/reducers/userSlice';

const CreateMiniApps = ({ setEnableEdit, fetchAllMiniApps }) => {
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [newProfile, setNewProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(
    'https://your-default-profile-pic-url.com'
  );
  const dispatch = useDispatch();
  const [showSocialLinks, setShowSocialLinks] = useState(false);

  // Social links states
  const [socialLinks, setSocialLinks] = useState({
    twitter: '',
    instagram: '',
    linkedIn: '',
    facebook: '',
    tiktok: '',
    snapchat: '',
    reddit: '',
    pinterest: '',
    medium: '',
    tumblr: '',
  });

  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    setSocialLinks((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    if (!username || !name || !website) {
      return false;
    }
    return true;
  };

  const CreateApp = () => {
    setLoading(true);

    // const payload = {
    //   name,
    //   company: username,
    //   link: website,
    //   description: bio,
    //   createdBy: userInfo?.username,
    //   status: 'Active',
    //   image: profilePic,
    // };
    const formData = new FormData();

    formData.append('name', name);
    formData.append('company', username);
    formData.append('link', website);
    formData.append('createdBy', userInfo?.username);
    formData.append('description', bio);
    formData.append('status', 'Active');

    if (newProfile) {
      formData.append('file', newProfile);
    }
    const updateProfile = async () => {
      await axios.post(
        'https://soapboxapi.megahoot.net/miniapps/create',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setLoading(false);
      setEnableEdit(false);
      fetchAllMiniApps();
    };

    toast.promise(updateProfile, {
      pending: `Creating App`,
      success: `${name} App Created successfully`,
      error: 'Please try again',
    });
  };

  const handleSubmit = () => {
    if (validate()) {
      CreateApp();
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: '0 auto',
        padding: 3,
        bgcolor: '#f9f9f9',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Create Mini App
      </Typography>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Avatar
          src={profilePic}
          alt="Profile"
          sx={{ width: 100, height: 100, margin: '0 auto', mb: 1 }}
        />
        <input
          accept="image/*"
          id="file-input"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setProfilePic(URL.createObjectURL(file));
              setNewProfile(file);
            }
          }}
        />
        <label htmlFor="file-input">
          <IconButton component="span" color="primary">
            <PhotoCamera />
          </IconButton>
        </label>
      </Box>
      <Box
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <TextField
          label="App Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Company / Sponsor Name"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          label="Description"
          variant="outlined"
          multiline
          minRows={3}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
        <TextField
          label="Link"
          variant="outlined"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
        {/* <Button
          variant="outlined"
          onClick={() => setShowSocialLinks(!showSocialLinks)}
        >
          {showSocialLinks ? 'Hide Social Links' : 'Add Social Links'}
        </Button> */}
        <Collapse in={showSocialLinks}>
          {Object.keys(socialLinks).map((link) => (
            <TextField
              key={link}
              label={link.charAt(0).toUpperCase() + link.slice(1)}
              name={link}
              variant="outlined"
              value={socialLinks[link]}
              onChange={handleSocialChange}
              sx={{ mt: 2 }}
            />
          ))}
        </Collapse>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Create App'}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateMiniApps;
