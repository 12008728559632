import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  CardContent,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  Card,
  Dialog,
  Stack,
  TextField,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';

import { useTheme } from '@emotion/react';
import {
  BookmarkAdd,
  CodeOutlined,
  Delete,
  DeleteForever,
  LinkOutlined,
  MoreVert,
  ShareOutlined,
  Visibility,
} from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { toast } from 'react-toastify';
import MarkdownPreview from '@uiw/react-markdown-preview';
import PublicProfilePage from '../publicProfilePage';
import { formatCount, formatSi } from '../../../utils';
import UserBadge from '../userBadge';
const FeedContent = ({ hoot, setFeedData, deleteHandler }) => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [showShareModal, setShowShareModal] = useState(false);

  const theme = useTheme();

  const handleLike = async (hootId) => {
    try {
      await axios.put('https://soapboxapi.megahoot.net/upload/increment-like', {
        hootId,
      });
      setFeedData((prevData) =>
        prevData.map((post) =>
          post.id === hoot.id ? { ...post, likes: post.likes + 1 } : post
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleRepost = async (postId) => {
    if (!userInfo) {
      return toast.error('Please login to repost');
    }
    if (window.confirm('Are you sure you want to repost this post?')) {
      try {
        await axios.post('https://soapboxapi.megahoot.net/upload/repost', {
          postId,
          username: userInfo.username,
          email: userInfo.email,
        });
        toast.success('Reposted from your timeline');
      } catch (error) {
        toast.error(JSON.stringify(error));
      }
    }
  };

  function copyTextToClipboard(text, message) {
    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(function () {
          console.log('Text copied to clipboard successfully!');
        })
        .catch(function (err) {
          console.error('Error copying text: ', err);
        });
    } else {
      // Fallback for older browsers
      let textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        console.log('Text copied to clipboard successfully!');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    }
    toast.success(message);
  }

  function base64Encode(input) {
    const encoder = new TextEncoder();
    const uint8Array = encoder.encode(input);
    return btoa(String.fromCharCode(...uint8Array));
  }

  const fontFamilyStyle =
    hoot?.fontFamilyStyle && hoot.fontFamilyStyle.includes('{')
      ? JSON.parse(hoot.fontFamilyStyle)
      : null;

  return (
    <>
      <Card
        elevation={50}
        sx={{
          borderRadius: 0,
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          bgcolor: '#000',
          color: '#fff',
          border: '1px solid grey',
          borderLeft: 'none',

          width: '400px',
        }}
      >
        <CardContent
          sx={{
            bgcolor: '#000',
            position: 'relative',
            height: '100%',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              color: '#fff',
            }}
          >
            <Link to={`/profile/${hoot.authorUsername}`}>
              <Avatar
                sx={{ height: '60px', width: '60px' }}
                src={`https://soapboxapi.megahoot.net/profile-pictures/${hoot.authorProfilePic}`}
              />
            </Link>

            <Typography
              variant="h6"
              mb={0}
              component="a"
              sx={{
                textDecoration: 'none',
                color: '#fff',
              }}
              // href={`/profile/${hoot.authorUsername}`}
            >
              @{hoot.authorUsername}
              {hoot.verified === 1 ? (
                <UserBadge
                  userData={{
                    badge: hoot.badge,
                    verified: hoot.verified,
                  }}
                />
              ) : null}
            </Typography>
            <Button
              sx={{
                border: `1px solid #5a3997`,
                color: '#fff',
                fontSize: '14px',
                padding: '5px',
                bgcolor: '#5a3997',
                width: '120px',
                marginLeft: '5px',
              }}
              size="large"
            >
              Follow
            </Button>
            {hoot?.repostData && (
              <Button
                sx={{
                  border: `1px solid #fff`,
                  color: '#fff',
                  fontSize: '10px',
                  padding: '2px',
                }}
              >
                Reposted
              </Button>
            )}
          </Box>

          {/* <MarkdownPreview
            source={hoot.caption}
            // style={
            //   fontFamilyStyle
            //     ? {
            //         all: 'unset',
            //         padding: 4,
            //         wordWrap: 'break-word',
            //         width: '100%',
            //         overflow: 'auto',
            //         color: fontFamilyStyle?.color,
            //         backgroundColor: fontFamilyStyle?.bgColor,
            //       }
            //     : {
            //         padding: 4,
            //         wordWrap: 'break-word',
            //         maxWidth: '100%',
            //         overflow: 'auto',
            //         maxHeight: '200px',
            //         backgroundColor: '#fff',
            //       }
            // }
            style={{
              padding: 4,
              wordWrap: 'break-word',
              maxWidth: '100%',
              overflow: 'auto',
              backgroundColor: '#fff',
            }}
          /> */}
          <Typography>{hoot?.caption?.replace('##', '')}</Typography>
          <Stack
            flexDirection="row"
            sx={{ position: 'absolute', bottom: '10px', width: '100%' }}
          >
            <div
              style={{
                backgroundColor: '#424242',
                flex: 0.9,
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                color: '#fff',
                overflow: 'hidden',
                padding: '5px',
              }}
            >{`https://www.mchathive.com/embed....`}</div>
            <Button
              variant="contained"
              sx={{
                bgcolor: '#a7a8aa8c',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
              }}
              onClick={() => {
                copyTextToClipboard(
                  `https://www.mchathive.com/embed/feed/${base64Encode(
                    hoot?.id
                  )}`,
                  'Post Url code copied successfully'
                );
              }}
              size="small"
            >
              Copy link
            </Button>
          </Stack>
          <CardContent
            sx={{
              position: 'absolute',
              bottom: '50px',
              right: '10px',
              backgroundColor: '#0000004d',
              color: '#fff',
              width: '100%',
              borderRadius: '30px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: '100%',
              }}
            >
              <IconButton
                sx={{ flexDirection: 'column' }}
                onClick={() => handleLike(hoot.id)}
                color="primary"
              >
                <ThumbUpIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />
                <Typography
                  variant="body2"
                  sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
                >
                  {formatCount(hoot.likes)}
                  {formatSi(hoot.likes)}
                </Typography>
              </IconButton>
              <IconButton
                sx={{ flexDirection: 'column' }}
                onClick={() => handleLike(hoot.id)}
                color="primary"
              >
                <Visibility
                  sx={{ color: theme.palette.primary.contrastText }}
                />
                <Typography
                  variant="body2"
                  sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
                >
                  {formatCount(hoot.views)}
                  {formatSi(hoot.views)}
                </Typography>
              </IconButton>
              <IconButton
                sx={{ flexDirection: 'column' }}
                onClick={() => handleRepost(hoot.id)}
              >
                <RepeatIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />

                <Typography
                  variant="body2"
                  sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
                >
                  {hoot.reposts}
                </Typography>
              </IconButton>
              <IconButton
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                <ShareOutlined
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                <BookmarkAdd
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </IconButton>
              {hoot?.authorUsername === userInfo?.username ? (
                <IconButton
                  onClick={() => {
                    deleteHandler(hoot);
                  }}
                >
                  <Delete sx={{ color: theme.palette.primary.contrastText }} />
                </IconButton>
              ) : null}
              {/* <IconButton
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                <MoreVert sx={{ color: theme.palette.primary.contrastText }} />
              </IconButton> */}
            </Box>
            <Dialog
              open={showShareModal}
              onClose={() => setShowShareModal(false)}
            >
              <Stack
                gap={2}
                sx={{
                  padding: '10px',
                  right: '30px',
                  top: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  <FacebookShareButton
                    url={`https://www.mchathive.com/embed/feed/${base64Encode(
                      hoot?.id
                    )}`}
                  >
                    <FacebookIcon borderRadius="100%" size="30px" />
                  </FacebookShareButton>
                  <Typography>Share to Facebook</Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  <TwitterShareButton
                    url={`https://www.mchathive.com/embed/feed/${base64Encode(
                      hoot?.id
                    )}`}
                  >
                    <TwitterIcon borderRadius="100%" size="30px" />
                  </TwitterShareButton>
                  <Typography>Share to Twitter</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  <RedditShareButton
                    url={`https://www.mchathive.com/embed/feed/${base64Encode(
                      hoot?.id
                    )}`}
                  >
                    <RedditIcon borderRadius="100%" size="30px" />
                  </RedditShareButton>
                  <Typography>Share to Reddit</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  <TelegramShareButton
                    url={`https://www.mchathive.com/embed/feed/${base64Encode(
                      hoot?.id
                    )}`}
                  >
                    <TelegramIcon borderRadius="100%" size="30px" />
                  </TelegramShareButton>
                  <Typography>Share to Telegram</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  <EmailShareButton
                    url={`https://www.mchathive.com/embed/feed/${base64Encode(
                      hoot?.id
                    )}`}
                  >
                    <EmailIcon borderRadius="100%" size="30px" />
                  </EmailShareButton>
                  <Typography>Share via Email</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  <IconButton
                    sx={{ width: '30px', height: '30px' }}
                    onClick={() => {
                      copyTextToClipboard(
                        `<iframe src="https://www.mchathive.com/embed/feed/${base64Encode(
                          hoot?.id
                        )}" name="hootiFrame" scrolling="no" width="100%" height=650></iframe>`,
                        'Iframe code copied successfully'
                      );
                    }}
                  >
                    <CodeOutlined />
                  </IconButton>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      copyTextToClipboard(
                        `<iframe src="https://www.mchathive.com/embed/feed/${base64Encode(
                          hoot?.id
                        )}" name="hootiFrame" scrolling="no" width="100%" height=650></iframe>`,
                        'Iframe code copied successfully'
                      );
                    }}
                  >
                    Copy Embed Code
                  </Typography>
                </Box>
              </Stack>
            </Dialog>
          </CardContent>
        </CardContent>
      </Card>

      {/* <Dialog
        maxWidth="lg"
        open={showProfile}
        fullWidth={true}
        onClose={() => setShowProfile(false)}
      >
        <PublicProfilePage
          username={selectedProfile?.username}
          setMessages={setMessages}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      </Dialog> */}
    </>
  );
};

export default FeedContent;
