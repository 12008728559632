import { ArrowBackIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

function IframeLoader({ iframeSrc, setTabValue }) {
  return (
    <div
      style={{
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <IconButton
        onClick={() => setTabValue(7)}
        sx={{
          position: 'absolute',
          left: 0,
          zIndex: 999999,
          backgroundColor: '#fff',
          padding: 1,
          width: '50px',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ArrowBackIos color="primary" />
      </IconButton>
      <iframe
        src={iframeSrc}
        width="100%"
        height="100%"
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

export default IframeLoader;
