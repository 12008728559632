import { CallMade, CallReceived } from '@mui/icons-material';
import {
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function RequestList({
  requestsData,
  handleDeleteRequest,
  handleApproveRequest,
  value,
  expandedViewDefault,
  expandedViewHandler,
}) {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [expandedView, setExpandedView] = useState(false);
  useEffect(() => {
    if (expandedViewDefault) {
      setExpandedView(expandedViewDefault);
    }
  }, []);
  return expandedView ? (
    <List>
      <Divider>New Requests</Divider>
      {requestsData &&
        requestsData
          ?.filter((e) => e.status.toLowerCase() === 'pending')
          .map((request, index) =>
            request.type === 'message' ? (
              <ListItem
                key={index}
                button
                style={{
                  borderRadius: 8,
                  marginBottom: 8,
                  padding: '15px 15px',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                }}
              >
                <Stack direction="row">
                  {request?.from === userInfo?.username ? (
                    <CallMade color="primary" style={{ marginRight: '5px' }} />
                  ) : (
                    <CallReceived
                      color="primary"
                      style={{ marginRight: '5px' }}
                    />
                  )}
                  {request?.from === userInfo?.username ? (
                    <Typography
                      sx={{ marginRight: '10px' }}
                    >{`${request?.to}`}</Typography>
                  ) : (
                    <Typography
                      sx={{ marginRight: '10px' }}
                    >{`${request?.from}`}</Typography>
                  )}
                </Stack>

                <Stack direction="row">
                  {request?.from === userInfo?.username ? (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteRequest(request)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        onClick={() => {
                          handleApproveRequest(request);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="outlined"
                        color="error"
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </Stack>
              </ListItem>
            ) : (
              <ListItem
                key={index}
                button
                style={{
                  borderRadius: 8,
                  marginBottom: 8,
                  padding: '15px 15px',
                  cursor: 'pointer',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Stack>
                  <Typography fontWeight="500" sx={{ marginRight: '10px' }}>
                    Channel: {request?.channelName}
                  </Typography>
                  <Typography fontWeight="500" sx={{ marginRight: '10px' }}>
                    User: {`${request?.username}`}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  {request?.username === userInfo?.username ? (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteRequest(request)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        onClick={() => {
                          handleApproveRequest(request);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteRequest(request)}
                      >
                        Reject
                      </Button>
                    </>
                  )}{' '}
                </Stack>
              </ListItem>
            )
          )}
      <Divider>Past Requests</Divider>
      {requestsData &&
        requestsData
          ?.filter((e) => e.status.toLowerCase() !== 'pending')
          .map((request, index) => (
            <ListItem
              key={index}
              button
              style={{
                borderRadius: 8,
                marginBottom: 8,
                padding: '15px 15px',
                cursor: 'pointer',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ alignItems: 'center', display: 'flex' }}>
                {request?.from === userInfo?.username ? (
                  <CallMade color="primary" style={{ marginRight: '5px' }} />
                ) : (
                  <CallReceived
                    color="primary"
                    style={{ marginRight: '5px' }}
                  />
                )}
                {request?.from === userInfo?.username
                  ? request.to
                  : request.from || request.username}
              </Typography>

              <Stack direction="row">
                <Chip
                  sx={{ ml: 1 }}
                  label={
                    ['failed', 'Rejected'].includes(request?.status)
                      ? 'Rejected'
                      : 'Accepted'
                  }
                  size="small"
                  variant="outlined"
                  color={
                    request.status === 'completed'
                      ? 'success'
                      : request.status === 'failed' ||
                        request.status === 'Rejected'
                      ? 'error'
                      : 'primary'
                  }
                ></Chip>
              </Stack>
            </ListItem>
          ))}
    </List>
  ) : (
    <List sx={{ maxHeight: '200px', overflow: 'auto', marginTop: '20px' }}>
      <Stack direction="row" justifyContent="space-between">
        <Button color="#fff" size="small" sx={{ fontSize: '1rem' }}>
          Requests
        </Button>
        <Button
          color="#c2c2c2"
          sx={{ color: '#c2c2c2' }}
          size="small"
          onClick={expandedViewHandler}
        >
          View All
        </Button>
      </Stack>

      {requestsData &&
        requestsData
          ?.filter((e) => e.status.toLowerCase() === 'pending')
          .map((request, index) =>
            request.type === 'message' ? (
              <ListItem
                key={index}
                button
                style={{
                  borderRadius: 8,
                  marginBottom: 8,
                  padding: '15px 15px',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                  backgroundColor: '#171d26',
                }}
              >
                <Stack direction="row">
                  {request?.from === userInfo?.username ? (
                    <CallMade color="primary" style={{ marginRight: '5px' }} />
                  ) : (
                    <CallReceived
                      color="primary"
                      style={{ marginRight: '5px' }}
                    />
                  )}
                  {request?.from === userInfo?.username ? (
                    <Typography
                      sx={{ marginRight: '10px' }}
                    >{`${request?.to}`}</Typography>
                  ) : (
                    <Typography
                      sx={{ marginRight: '10px' }}
                    >{`${request?.from}`}</Typography>
                  )}
                </Stack>

                <Stack direction="row">
                  {request?.from === userInfo?.username ? (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteRequest(request)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        onClick={() => {
                          handleApproveRequest(request);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="outlined"
                        color="error"
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </Stack>
              </ListItem>
            ) : (
              <ListItem
                key={index}
                button
                style={{
                  borderRadius: 8,
                  marginBottom: 8,
                  padding: '15px 15px',
                  cursor: 'pointer',
                  width: '100%',
                  justifyContent: 'space-between',
                  backgroundColor: '#171d26',
                }}
              >
                <Stack>
                  <Typography fontWeight="500" sx={{ marginRight: '10px' }}>
                    Channel: {request?.channelName}
                  </Typography>
                  <Typography fontWeight="500" sx={{ marginRight: '10px' }}>
                    User: {`${request?.username}`}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  {request?.username === userInfo?.username ? (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteRequest(request)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        onClick={() => {
                          handleApproveRequest(request);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="small"
                        sx={{ borderRadius: 5, mx: 0.5 }}
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteRequest(request)}
                      >
                        Reject
                      </Button>
                    </>
                  )}{' '}
                </Stack>
              </ListItem>
            )
          )}

      {requestsData &&
        requestsData
          ?.filter((e) => e.status.toLowerCase() !== 'pending')
          .map((request, index) => (
            <ListItem
              key={index}
              button
              style={{
                borderRadius: 8,
                marginBottom: 8,
                padding: '15px 15px',
                cursor: 'pointer',
                justifyContent: 'space-between',
                backgroundColor: '#171d26',
              }}
            >
              <Typography sx={{ alignItems: 'center', display: 'flex' }}>
                {request?.from === userInfo?.username ? (
                  <CallMade
                    color="#c2c2c2"
                    style={{ marginRight: '5px', color: '#c2c2c2' }}
                  />
                ) : (
                  <CallReceived
                    color="#c2c2c2"
                    style={{ marginRight: '5px', color: '#c2c2c2' }}
                  />
                )}
                {request?.from === userInfo?.username
                  ? request.to
                  : request.from || request.username}
              </Typography>

              <Stack direction="row">
                <Chip
                  sx={{ ml: 1 }}
                  label={
                    ['failed', 'Rejected'].includes(request?.status)
                      ? 'Rejected'
                      : 'Accepted'
                  }
                  size="small"
                  variant="outlined"
                  color={
                    request.status === 'completed'
                      ? 'success'
                      : request.status === 'failed' ||
                        request.status === 'Rejected'
                      ? 'error'
                      : 'primary'
                  }
                ></Chip>
              </Stack>
            </ListItem>
          ))}
    </List>
  );
}

export default RequestList;
