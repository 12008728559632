import { useTheme } from '@emotion/react';
import {
  Done,
  DoneAll,
  MoreVertOutlined,
  Edit,
  Reply,
  AddReaction,
  Delete,
} from '@mui/icons-material';
import {
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
  Avatar,
  Dialog,
  Card,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Emojione } from 'react-emoji-render';
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';
import { formatDate } from '../../utils';

function isOnlyEmojis(input) {
  // Regex to match emojis
  const emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\uFE0F]/gu;

  // Remove all emojis from the input string
  const withoutEmojis = input.replace(emojiRegex, '').trim();

  // If the input is empty after removing emojis, it's only emojis
  return withoutEmojis.length === 0;
}

function MessageCard({
  message,
  handleDeleteChat,
  setEditingMessage,
  editingMessage,
  replyEnable,
  setReplyEnable,
  setEditEnable,
  setNewMessage,
  handleQuicReply,
}) {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.userInfo);
  const [isHovered, setIsHovered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEmojiList, setShowEmojiList] = useState(false);
  const [expand, setExpand] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowPopup(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isOwnMessage = message.user._id === userInfo.username;
  const backgroundColor = isOwnMessage
    ? theme.palette.primary.light
    : '#121820';
  const textColor = isOwnMessage ? '#fff' : '#a3a8aa';

  const mediaContent = (message) => {
    if (message.text.match(/\.(gif|png|jpg|jpeg|eps|webp)$/i)) {
      return (
        <img
          onClick={() => setExpand(true)}
          src={message.text.replace('http://', 'https://')}
          alt="mchathive"
          style={{
            width: '100%',
            objectFit: 'contain',
            borderRadius: '8px',
            maxHeight: expand ? '100%' : '360px',
          }}
          draggable={false}
        />
      );
    }
    if (message.text.match(/\.(mp3|wav)$/i)) {
      return (
        <audio
          src={message.text.replace('http://', 'https://')}
          controls
          style={{ width: '100%', maxWidth: '360px', maxHeight: '360px' }}
        />
      );
    }
    if (message.text.match(/\.(mp4|mov|wmv|webm|avi)$/i)) {
      return (
        <video
          src={message.text.replace('http://', 'https://')}
          controls
          style={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '360px',
            maxHeight: '360px',
          }}
          controlsList="nodownload" // Remove download option
          draggable={false}
        />
      );
    }
    if (message.text.match(/\.(pdf|doc|docx|xls|xlsx|txt)$/i)) {
      return (
        <iframe
          style={{ maxWidth: '280px', minWidth: '100px', width: 'fit-content' }}
          src={message.text}
          allowFullScreen={true}
        />
      );
    }

    return null;
  };

  const reactionEmojis = ['👍', '❤️', '😂', '😮'];

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'relative',
        maxWidth: '500px',
        width: '60%',
        minWidth: '100px',
        padding: '10px 15px',
        borderRadius: '20px',
        wordBreak: 'break-word',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: isOwnMessage ? 'row-reverse' : 'row',
      }}
      draggable={false}
    >
      <Typography
        variant="caption"
        style={{
          display: 'block',

          textAlign: 'left',
          color: isOwnMessage ? '#f0f0f0' : 'grey',

          margin: '5px 10px 5px 5px',
        }}
      >
        <Avatar src={message?.user?.avatar} alt={message.user?.name} />
      </Typography>
      <Stack direction="column">
        <Card sx={{ background: backgroundColor, padding: '1rem' }}>
          {message.replyParent && (
            <Box
              sx={{
                bgcolor: isOwnMessage ? '#dccfeb' : '#f0f0f0',
                color: '#000',
                borderRadius: '8px',
                padding: '8px',
              }}
            >
              {mediaContent(message.replyParent) || (
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 500,
                    color: '#000',
                  }}
                >
                  <Emojione text={message?.replyParent?.text} />
                </Typography>
              )}

              <Typography
                variant="caption"
                style={{
                  display: 'block',
                  marginTop: 5,
                  textAlign: 'right',
                  color: textColor,
                }}
              >
                {new Date(message.replyParent.createdAt).toLocaleTimeString()}
              </Typography>
            </Box>
          )}
          {message.quickReply && (
            <div
              style={{
                position: 'absolute',
                bottom: '-5px',
                backgroundColor: '#f0f0f0',
                borderRadius: '100%',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {message.quickReply}
            </div>
          )}
          {mediaContent(message) ||
            (message?.text ? (
              <Typography
                variant="body2"
                style={{
                  fontWeight: 500,
                  color: textColor,
                }}
              >
                <Emojione
                  text={message?.text}
                  style={{ fontSize: isOnlyEmojis(message?.text) && '60px' }}
                />
              </Typography>
            ) : null)}

          {message.seen ? (
            <DoneAll
              fontSize="small"
              color="inherit"
              sx={{ color: '#a3a8aa' }}
            />
          ) : (
            <Done fontSize="small" color="inherit" />
          )}
          {/* Popup for Reactions and Options */}
          {isHovered && (
            <div
              style={{
                position: 'absolute',
                top: '-40px',
                right: '-10px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                padding: '5px',
                zIndex: 10,
              }}
              onMouseEnter={() => setShowPopup(true)}
              onMouseLeave={() => setShowPopup(false)}
            >
              {/* Quick Reactions */}
              {reactionEmojis.map((emoji, index) => (
                <IconButton
                  key={index}
                  size="small"
                  onClick={() => {
                    setEditingMessage(message);
                    handleQuicReply(emoji, message);
                  }}
                >
                  <span style={{ fontSize: '16px' }}>{emoji}</span>
                </IconButton>
              ))}

              {/* Add More Reactions */}
              <IconButton
                size="small"
                onClick={() => {
                  setShowEmojiList((prev) => !prev);
                }}
              >
                <AddReaction fontSize="small" />
              </IconButton>

              {/* Reply Action */}
              <IconButton
                size="small"
                onClick={() => {
                  setReplyEnable(true);
                  setEditingMessage(message);
                }}
              >
                <Reply fontSize="small" />
              </IconButton>

              {/* More Options */}
              <IconButton size="small" onClick={handleMenuOpen}>
                <MoreVertOutlined fontSize="small" />
              </IconButton>
            </div>
          )}
          {showEmojiList && (
            <div
              style={
                isOwnMessage
                  ? {
                      position: 'absolute',
                      top: '0px',
                      right: '40%',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      backgroundColor: '#fff',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      padding: '5px',
                      zIndex: 10,
                    }
                  : {
                      position: 'absolute',
                      top: '0px',
                      left: '40%',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      backgroundColor: '#fff',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      padding: '5px',
                      zIndex: 10,
                    }
              }
              onMouseEnter={() => setShowEmojiList(true)}
              onMouseLeave={() => setShowEmojiList(false)}
            >
              <EmojiPicker
                onEmojiClick={({ emoji }) => {
                  setEditingMessage(message);
                  handleQuicReply(emoji, message);
                  setShowEmojiList(false);
                }}
              />
            </div>
          )}
        </Card>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginTop: 5,
            textAlign: 'right',
            color: textColor,
          }}
        >
          {formatDate(message.createdAt)}
        </Typography>
      </Stack>

      {/* More Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            handleDeleteChat(message); /* Handle delete */
          }}
        >
          <Delete fontSize="small" style={{ marginRight: '8px' }} /> Delete
          Message
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEditEnable(true);
            setEditingMessage(message);
            setNewMessage(message?.text);
          }}
        >
          <Edit fontSize="small" style={{ marginRight: '8px' }} /> Edit Message
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose(); /* Handle more actions */
          }}
        >
          <MoreVertOutlined fontSize="small" style={{ marginRight: '8px' }} />{' '}
          More Actions
        </MenuItem>
      </Menu>

      <Dialog
        maxWidth="md"
        open={expand}
        fullWidth={true}
        onClose={() => setExpand(false)}
      >
        {mediaContent(message)}
      </Dialog>
    </div>
  );
}

export default MessageCard;
