import EmojiPicker from 'emoji-picker-react';
import React from 'react';

function EmojiContainer({ showEmojiList, setShowEmojiList, setNewMessage }) {
  return (
    showEmojiList && (
      <div
        style={{
          position: 'absolute',
          bottom: '80px',
          left: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          padding: '5px',
          zIndex: 10,
        }}
        onMouseEnter={() => setShowEmojiList(true)}
        onMouseLeave={() => setShowEmojiList(false)}
      >
        <EmojiPicker
          onEmojiClick={({ emoji }) => {
            setNewMessage(emoji);

            setShowEmojiList(false);
          }}
        />
      </div>
    )
  );
}

export default EmojiContainer;
