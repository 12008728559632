import React, { useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useRouter } from '../components/hooks';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setChatDataList, updateUser } from '../redux/reducers/userSlice';
import { jwtDecode } from 'jwt-decode';
const baseUrl = `https://soapboxapi.megahoot.net`;
function LoadingScreen() {
  const dispatch = useDispatch();
  const { userInfo, chatData, chatListData } = useSelector(
    (state) => state.userInfo
  );
  const router = useRouter();

  const fetchAuthToken = (payload) => {
    const { email, username } = payload;
    axios
      .post(`${baseUrl}/api/v2/auth/sign`, { email, username })
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem('mchathive-protocol-v2-onboarded', true);
        localStorage.setItem('mchathive-auth-token-v2', token);
        router.push('/home');
      })
      .catch((err) => {
        localStorage.setItem('mchathive-protocol-v2-onboarded', true);
        router.push('/login');
      });
  };
  const fetchChatDataList = (username) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivateinbox', {
        to: username,
      })
      .then((res) => {
        const payload = res.data.sort(
          (b, a) =>
            new Date(a.chat.timestamp).getTime() -
            new Date(b.chat.timestamp).getTime()
        );
        dispatch(setChatDataList(payload));
      });
  };
  const fetchuserData = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/user/${username}`)
      .then((res) => {
        // setUser(res.data[0]);
        if (res.data) {
          const isOnBordedToV2 = localStorage.getItem(
            'mchathive-protocol-v2-onboarded'
          );
          if (isOnBordedToV2) {
            // navigation.navigate('Home', { data: res.data });
            //saveUserProfile(JSON.stringify(res.data));
            fetchChatDataList(username);
            dispatch(updateUser(res.data[0]));
            router.push('/home');
          } else {
            fetchAuthToken({ username, email: res.data[0].email });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const isOnBordedToV2 = localStorage.getItem(
      'mchathive-protocol-v2-onboarded'
    );
    if (isOnBordedToV2) {
      const jwtToken = localStorage.getItem('mchathive-auth-token-v2');

      if (jwtToken) {
        const userData = jwtDecode(jwtToken);
        const { username } = userData;
        fetchuserData(username);
      } else {
        router.push('/login');
      }
    } else {
      const existingTokenraw = localStorage.getItem('mchathive-auth-token');

      if (existingTokenraw && existingTokenraw !== '') {
        const tokenRow = JSON.parse(existingTokenraw);
        fetchuserData(tokenRow.username);
      } else {
        router.push('/login');
      }
    }
  }, []);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress />
    </Box>
  );
}

export default LoadingScreen;
