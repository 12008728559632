import { Box } from '@mui/material';
import React from 'react';

function UserTyping({ isTyping }) {
  return (
    isTyping && (
      <Box
        sx={{
          minHeight: '30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <img src="/typing.gif" width="100px" alt="typing" />
      </Box>
    )
  );
}

export default UserTyping;
