import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function ChannelCard({
  item,
  setSelectedChannel,
  setOpenChannel,
  handleChannelClickHandler,
  handleChatRequest,
}) {
  const { userInfo } = useSelector((state) => state.userInfo);
  return (
    <Grid item xs={12} md={12}>
      <Card
        sx={{
          backgroundColor: '#171d26',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          px: '10px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setSelectedChannel(item);
          setOpenChannel(true);
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            px: 0,
            alignItems: 'center',
          }}
        >
          <Avatar
            src={`http://soapboxapi.megahoot.net/profile-pictures/${
              item?.profilePic ?? item?.image
            }`}
            alt={item?.name}
            sx={{ mx: 1, height: '60px', width: '60px', bgcolor: '#3d215b' }}
          />
          <Stack sx={{ py: 0 }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {item?.name}
            </Typography>
            <Typography variant="body2" color="#fff">
              @{item.username}
            </Typography>
          </Stack>
        </CardContent>
        <Stack direction="column">
          {/* <Button
            color="primary"
            variant="contained"
            sx={{ width: '120px' }}
            // sx={{ backgroundColor: '#03875f' }}
           
          >
            Open
          </Button> */}
          <Button
            variant="contained"
            sx={{ width: '120px', color: '#fff' }}
            // sx={{ color: '#03875f', borderColor: '#03875f' }}

            onClick={() => {
              userInfo.username === item?.owner ||
              JSON.parse(item.members).includes(userInfo.username)
                ? handleChannelClickHandler(item)
                : handleChatRequest(item);
            }}
            // endIcon={<ShareIcon />}
          >
            {userInfo.username === item?.owner ||
            JSON.parse(item.members).includes(userInfo.username)
              ? 'Chat Room'
              : 'Subscribe'}
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
}

export default ChannelCard;
