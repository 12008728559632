import React from 'react';

function UserBadge({ userData }) {
  const { badge, verified } = userData || {};

  return verified ? (
    <>
      {badge === 'Premium' ? (
        <img
          src="/PremiumVerified.svg"
          height="18px"
          width="18px"
          alt="premium_verified"
        />
      ) : badge === 'Corporate' ? (
        <img
          src="/PurpleVerified.svg"
          height="18px"
          width="18px"
          alt="corporate_verified"
        />
      ) : badge === 'Notable' ? (
        <img
          src="/PurpleVerified.svg"
          height="18px"
          width="18px"
          alt="corporate_verified"
        />
      ) : badge === 'General' ? (
        <img
          src="/PurpleVerified.svg"
          height="18px"
          width="18px"
          alt="corporate_verified"
        />
      ) : badge === 'Media' ? (
        <img
          src="/MediaVerified.svg"
          height="18px"
          width="18px"
          alt="corporate_verified"
        />
      ) : (
        <img
          src="/RegularVerified.svg"
          height="18px"
          width="18px"
          alt="regular_verified"
        />
      )}
    </>
  ) : null;
}

export default UserBadge;
