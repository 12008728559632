import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  IconButton,
  CircularProgress,
  Box,
  Dialog,
  useMediaQuery,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@emotion/react';
import { ArrowBack } from '@mui/icons-material';
import FeedCard from './feeds/feedCard';
import { fetchFeed } from '../../utils';
import IndividualPostOverlay from './individualPostOverlay';

const Feeds = ({ setShowLeft }) => {
  const [feedData, setFeedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [playingVideos, setPlayingVideos] = useState({});
  const [postData, setPostData] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const videoRefs = useRef([]);
  const observerRefs = useRef([]);
  const autoScrollTimeout = useRef(null);
  const userScrollTimeout = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const togglePlayPause = (index) => {
    const video = videoRefs.current[index];
    if (!video) return; // Safety check to avoid errors if the video element doesn't exist

    if (!video.paused) {
      // Only pause if the video is currently playing
      video.pause();
      setPlayingVideos((prevState) => ({
        ...prevState,
        [index]: false,
      }));
    } else {
      // Play the video if it is paused
      video.play();
      setPlayingVideos((prevState) => ({
        ...prevState,
        [index]: true,
      }));
    }
  };

  const scrollToNext = (currentIndex) => {
    if (!isUserScrolling && currentIndex < feedData.length - 1) {
      const nextItem = document.getElementById(`feed-item-${currentIndex + 1}`);
      if (nextItem) {
        nextItem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const createObserver = (index) => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !isUserScrolling && !openPost) {
          const item = feedData[index];
          if (item.mimeType.includes('video')) {
            // Handle video playback
            videoRefs.current[index]?.play();
            videoRefs.current[index].onended = () => {
              scrollToNext(index);
            };
          } else {
            // Handle non-video content with auto-scroll
            clearTimeout(autoScrollTimeout.current); // Clear any existing timeout
            autoScrollTimeout.current = setTimeout(() => {
              scrollToNext(index);
            }, 5000); // Adjust delay as necessary
          }
        } else {
          // Pause video if it's out of view and clear auto-scroll timeout
          videoRefs.current[index]?.pause();
          clearTimeout(autoScrollTimeout.current);
        }
      },
      { threshold: 0.7 }
    );
    observer.observe(observerRefs.current[index]);
    return observer;
  };

  const nextHandler = () => {
    fetchFeed(
      Math.ceil(feedData.length / 10) + 1,
      setLoading,
      setFeedData,
      setHasMore
    );
  };

  const handleUserScroll = () => {
    clearTimeout(userScrollTimeout.current);
    setIsUserScrolling(true);
    userScrollTimeout.current = setTimeout(() => {
      setIsUserScrolling(false);
    }, 2000);
  };

  useEffect(() => {
    fetchFeed(1, setLoading, setFeedData, setHasMore);
    window.addEventListener('scroll', handleUserScroll);

    return () => {
      window.removeEventListener('scroll', handleUserScroll);
      clearTimeout(autoScrollTimeout.current);
      clearTimeout(userScrollTimeout.current);
    };
  }, []);

  useEffect(() => {
    const observers = feedData.map((_, index) => createObserver(index));
    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [feedData]);

  return (
    <Grid item xs={12} sm={12}>
      {isMobile && (
        <IconButton
          style={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            backgroundColor: '#f0f0f0',
            zIndex: 999999,
          }}
          onClick={() => setShowLeft(true)}
        >
          <ArrowBack />
        </IconButton>
      )}
      <InfiniteScroll
        height={'100vh'}
        dataLength={feedData.length}
        next={nextHandler}
        hasMore={hasMore}
        loader={
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        }
        style={{ flex: 1, overflowY: 'auto' }}
      >
        {feedData.map((item, index) => (
          <FeedCard
            key={`feed-item-${index}`}
            id={`feed-item-${index}`}
            item={item}
            index={index}
            setOpenPost={setOpenPost}
            setPostData={setPostData}
            observerRefs={observerRefs}
            videoRefs={videoRefs}
            togglePlayPause={togglePlayPause}
            setFeedData={setFeedData}
          />
        ))}
      </InfiniteScroll>

      {postData && postData.id && (
        <Dialog
          open={openPost}
          onClose={() => {
            setOpenPost(false);
            setPostData(null);
          }}
          maxWidth="md"
        >
          <IndividualPostOverlay postData={postData} />
        </Dialog>
      )}
    </Grid>
  );
};

export default Feeds;
