import { Box, CircularProgress, ListItem, Typography } from '@mui/material';
import React from 'react';
import MessageCard from '../messageCard';

function MessageRenderer({
  messages,
  userInfo,
  handleDeleteChat,
  setEditingMessage,
  editingMessage,
  replyEnable,
  setReplyEnable,
  setEditEnable,
  setNewMessage,
  handleQuicReply,
  editEnable,
  loading,
}) {
  return messages && messages.length > 0 ? (
    messages?.map((message, index) => (
      <ListItem
        key={index}
        style={{
          display: 'flex',
          justifyContent:
            message?.user?._id === userInfo.username
              ? 'flex-end'
              : 'flex-start',
        }}
      >
        <MessageCard
          message={message}
          handleDeleteChat={handleDeleteChat}
          setEditingMessage={setEditingMessage}
          editingMessage={editingMessage}
          replyEnable={replyEnable}
          editEnable={editEnable}
          setEditEnable={setEditEnable}
          setReplyEnable={setReplyEnable}
          setNewMessage={setNewMessage}
          handleQuicReply={handleQuicReply}
        />
      </ListItem>
    ))
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography>This is the start of your connversation</Typography>
      )}
    </Box>
  );
}

export default MessageRenderer;
